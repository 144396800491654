import { tokens } from "@bees-grow-shared/theme";
import { Paragraph } from "@hexa-ui/components";
import styled from "styled-components";

export const Title = styled(Paragraph)`
  color: ${tokens.color.foundation.txt.default};
  margin-bottom: ${tokens.measure.space["spacing-1"]};
  line-height: 1.25rem;
  white-space: nowrap;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${tokens.measure.space["spacing-1"]};
`;

export const Value = styled(Paragraph)`
  color: ${tokens.color.foundation.txt.default};
  line-height: ${tokens.measure["line-height"]["lineheight-5"]};
  max-width: 100%;
  font-size: ${tokens.measure["font-size"]["fontsize-2"]};
`;
