import { RewardsDomain } from "@/domains/rewardsDomain";

export interface RewardsState extends RewardsDomain {
  isLoading: boolean;
  error: boolean;
  done: boolean;
}

export const rewardsInitialState: RewardsState = {
  rewards: {
    balance: 0,
    dateRegistered: "",
    tier: "",
  },
  isLoading: false,
  error: false,
  done: false,
};
