import { RewardsDomain } from "@/domains/rewardsDomain";
import { getRewards } from "@/services/rewards/getRewards/GetRewardsService";
import { getRewardsConverter } from "@/useCases/rewards/getRewards/converters/getRewardsConverter";

interface GetRewardsProps {
  accountId: string;
  vendorId: string;
}

export const getRewardsUseCase = async ({
  accountId,
  vendorId,
}: GetRewardsProps): Promise<RewardsDomain> => {
  const response = await getRewards({
    path: { accountId },
    headers: { vendorId },
  });

  return getRewardsConverter(response);
};
