import { createEffect, createEvent } from "effector";

import { getCallCounterUseCase } from "@/useCases/openCall/getCallCounter/GetCallCounterUseCase";
import { upsertOpenCallUseCase } from "@/useCases/openCall/upsertOpenCall/UpsertOpenCallUseCase";

import { OpenCallState } from "./OpenCallState";

interface UpsertOpenCallProps {
  callId: string;
  vendorId: string;
  startCall: Date;
}

interface GetCallCounterProps {
  accountId: string;
  vendorId: string;
}

export const setIsStartedCall =
  createEvent<Partial<OpenCallState>>("setIsStartedCall");

export const setIsModalOpen =
  createEvent<Partial<OpenCallState>>("setIsModalOpen");

export const upsertOpenCall = createEffect({
  async handler(props: UpsertOpenCallProps): Promise<void> {
    await upsertOpenCallUseCase(props);
  },
});

export const getCallCounter = createEffect({
  async handler(props: GetCallCounterProps): Promise<{
    callAttempts: number;
  }> {
    return await getCallCounterUseCase(props);
  },
});
