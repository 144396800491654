import styled from "styled-components";

export const Container = styled.div<{ hasTooltip: boolean }>`
  div[data-radix-popper-content-wrapper] {
    display: ${({ hasTooltip }) => (hasTooltip ? "block" : "none")};
  }

  @media (max-width: 1024px) {
    & > div {
      height: 100%;
    }
  }
`;
