import { tokens } from "@bees-grow-shared/theme";
import { Card } from "@hexa-ui/components";
import styled from "styled-components";

const DROPDOWN_OPEN_WIDTH = "168%";
const DROPDOWN_OPEN_HEIGHT = "81.4vh";
const DROPDOWN_CLOSED_HEIGHT = "7rem";

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;
const padding = `${tokens.measure.space["spacing-4"]} ${tokens.measure.space["spacing-1"]} 0 ${tokens.measure.space["spacing-4"]}`;

export const Container = styled(Card)<{
  isDropdownOpen: boolean;
  isError: boolean;
}>`
  position: absolute;
  overflow: hidden;
  max-width: 41.1875rem;
  max-height: 39.0625rem;
  width: ${(props) => (props.isDropdownOpen ? DROPDOWN_OPEN_WIDTH : "100%")};
  height: ${(props) =>
    props.isDropdownOpen ? DROPDOWN_OPEN_HEIGHT : DROPDOWN_CLOSED_HEIGHT};
  z-index: 2;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${(props) => (props.isError ? 0 : padding)};

  box-shadow: ${(props) =>
    props.isDropdownOpen
      ? tokens.shadow["elevation-4"]
      : tokens.shadow["elevation-025"]};
  & > div {
    width: 100%;
  }
  transition: ${(props) => (props.isError ? "none" : "all 500ms ease-in-out")};
`;

export const ContainerDisabled = styled.div`
  padding: ${tokens.measure.space["spacing-4"]};
  gap: ${tokens.measure.space["spacing-2"]};
  background-color: ${tokens.color.primitive.transparent.black["black-4"]};
  display: grid;
  height: 100%;
`;

export const TextHyphenContainerDisabled = styled.p`
  color: ${tokens.color.foundation.txt["disabled"]};
  font-size: ${tokens.measure["font-size"]["fontsize-5"]};
  font-weight: ${tokens.fonts["font-weight"]["semibold"]};
  font-family: ${tokens.fonts["font-family"]["Barlow"]};
  margin: 0;
`;

export const TextContainerDisabled = styled(TextHyphenContainerDisabled)`
  font-size: ${tokens.measure["font-size"]["fontsize-2"]};
  line-height: ${tokens.measure["line-height"]["lineheight-5"]};
`;
