import { useIntl } from "react-intl";

import { RetryAlert } from "@bees-grow-shared/components";

import { CallTimerInfo } from "@/components/callTImerInfo/CallTimerInfo";
import { CreditAndPoints } from "@/components/creditAndPoints/CreditAndPoints";
import { PocInformation } from "@/components/pocInformation/PocInformation";
import { TopLineGap } from "@/components/topLineGap/TopLineGap";

import { useSummary } from "./hooks/useSummary";
import * as Styled from "./Summary.styles";

interface ErrorBehaviorProps {
  error: string;
  retry: () => void;
}

const SummaryPage = (): JSX.Element => {
  const { errorMessage, handleRetry } = useSummary();

  return (
    <>
      {errorMessage && (
        <ErrorBehavior retry={handleRetry} error={errorMessage} />
      )}
      <Styled.MainContainer data-testid={`${SummaryPage.name}`}>
        <PocInformation />
        <TopLineGap />
        <CreditAndPoints />
        <CallTimerInfo />
      </Styled.MainContainer>
    </>
  );
};

const ErrorBehavior = ({ error, retry }: ErrorBehaviorProps) => {
  const { formatMessage } = useIntl();
  return (
    <Styled.Container>
      <RetryAlert
        message={error}
        actionLabel={formatMessage({ id: "SummaryError.RETRY" })}
        action={retry}
      />
    </Styled.Container>
  );
};

export default SummaryPage;
