export enum OpenCallUseCase {
  START_CALL = "START_CALL",
  END_CALL = "END_CALL",
  PLACE_ORDER = "PLACE_ORDER",
}

export interface UpsertOpenCallRequestBody {
  startCall: Date;
}

export interface UpsertOpenCallRequestHeaders {
  vendorId: string;
}

export interface UpsertOpenCallParams {
  path: {
    callId: string;
  };
  query: {
    useCase: string;
  };
  headers: {
    vendorId: string;
  };
}
