import { getCallCounter } from "@/services/openCall/getCallCounter/GetCallCounterService";

interface UpsertOpenCallUseCaseProps {
  accountId: string;
  vendorId: string;
}

export const getCallCounterUseCase = async ({
  accountId,
  vendorId,
}: UpsertOpenCallUseCaseProps): Promise<{
  callAttempts: number;
}> => {
  const { attempts } = await getCallCounter({
    path: { accountId },
    headers: { vendorId },
  });

  return {
    callAttempts: attempts,
  };
};
