import { tokens } from "@bees-grow-shared/theme";
import {
  Heading,
  Paragraph,
  SkeletonLoader,
  TextLink,
} from "@hexa-ui/components";
import styled from "styled-components";

export const CardHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: ${tokens.measure.space["spacing-4"]};
`;

export const HeaderContent = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: ${tokens.measure.space["spacing-4"]};
  align-items: flex-end;
  justify-content: space-between;

  & > div {
    display: flex;
    flex-direction: column;
    max-width: calc(100% - 7.25rem);
  }
`;

export const PocName = styled(Heading)`
  margin-bottom: ${tokens.measure.space["spacing-2"]};
  color: ${tokens.color.foundation.txt.default};
  max-width: calc(100% - 1rem);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const OwnerTitle = styled(Paragraph)`
  color: ${tokens.color.foundation.txt.default};
  margin-bottom: ${tokens.measure.space["spacing-1"]};
  line-height: 1.25rem;
`;

export const OwnerName = styled(Paragraph)`
  color: ${tokens.color.foundation.txt.default};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const CollapsibleButton = styled(TextLink)`
  font-weight: ${tokens.fonts["font-weight"].medium};
  color: ${tokens.color.foundation.txt.info};
  font-size: ${tokens.measure["font-size"]["fontsize-2"]};
  line-height: ${tokens.measure["line-height"]["lineheight-3"]};
  margin: 0.25rem 0;
  margin-right: 1rem;
  white-space: nowrap;

  &:hover {
    text-decoration: none;
  }
`;

export const SkeletonLoaderPocName = styled(SkeletonLoader)`
  width: 13.625rem;
  padding: 0.125rem 0;
  height: 1.5rem;
  margin-bottom: ${tokens.measure.space["spacing-2"]};
`;

export const SkeletonLoaderOwnerTitle = styled(SkeletonLoader)`
  width: 5rem;
  padding: 0.1875rem 0;
  height: 1.25rem;
  margin-bottom: ${tokens.measure.space["spacing-1"]};
`;
export const SkeletonLoaderOwnerName = styled(SkeletonLoader)`
  width: 8.25rem;
  padding: 0.25rem 0;
  height: 1.5rem;
`;

export const SkeletonLoaderCollapsibleButton = styled(SkeletonLoader)`
  width: 5.375rem;
  padding: 0.25rem 0;
  height: 1.5rem;
  margin-right: 1rem;
`;
