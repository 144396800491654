import { axiosInstance } from "@bees-grow-shared/services";

import { BasePathParamsRequest } from "@/@types/BasePathParamsRequest";

import { GROW_REWARDS_ENDPOINTS } from "../endpoints";
import { RewardsResponse } from "./GetRewardsResponse";

export const getRewards = async (
  params: BasePathParamsRequest,
): Promise<RewardsResponse> => {
  const { data } = await axiosInstance.get<RewardsResponse>(
    GROW_REWARDS_ENDPOINTS.GET_REWARDS(params),
    { headers: { ...params.headers } },
  );

  return data;
};
