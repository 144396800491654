import { tokens } from "@bees-grow-shared/theme";
import { Card, Heading, Paragraph, ProgressMeter } from "@hexa-ui/components";
import styled from "styled-components";

export const Container = styled(Card)<{ gap?: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${tokens.measure.space["spacing-4"]};
  & > div {
    width: 100%;
  }

  &[disabled] {
    background-color: ${tokens.color.primitive.transparent.black["black-4"]};
    border-color: rgba(20, 20, 20, ${tokens.measure.opacity.light});
  }

  &[disabled] h1,
  &[disabled] h5,
  &[disabled] span,
  &[disabled] p {
    color: rgba(20, 20, 20, ${tokens.measure.opacity.medium});
  }

  ${(p) => p.gap && `gap: ${tokens.measure.space["spacing-2"]};`}
`;

export const ProgressBar = styled(ProgressMeter)`
  & > progress {
    border-radius: ${tokens.measure["border-radius"]["borderradius-4"]};
    width: 100%;
    height: 0.5rem;
  }
  & > progress::-webkit-progress-bar {
    background-color: ${tokens.color.component.bg["progress"].track.default};

    border-radius: ${tokens.measure["border-radius"]["borderradius-4"]};
    height: 0.5rem;
  }
  & > progress::-webkit-progress-value {
    background-color: ${tokens.color.component.bg["data-vis"]["datablue-70"]};
    border-radius: ${tokens.measure["border-radius"]["borderradius-4"]};
    height: 0.5rem;
  }
`;

export const Title = styled(Heading)`
  margin-bottom: ${tokens.measure.space["spacing-1"]};
  font-size: ${tokens.measure["font-size"]["fontsize-2"]};
  font-weight: ${tokens.fonts["font-weight"].semibold};
  font-family: ${tokens.fonts["font-family"]["Work Sans"]} !important;
  color: ${tokens.color.component.txt["segmented-control"].item.default};
  line-height: ${tokens.measure["line-height"]["lineheight-5"]};
`;

export const Text = styled(Paragraph)<{
  bold?: string;
  "align-center"?: string;
}>`
  margin: 0px;
  font-size: ${tokens.measure["font-size"]["fontsize-1"]};
  font-weight: ${(p) =>
    p.bold
      ? tokens.fonts["font-weight"].semibold
      : tokens.fonts["font-weight"].normal};
  ${(p) => !p["align-center"] && "width: 100%"};
  color: ${tokens.color.component.txt["segmented-control"].item.default};
  line-height: ${tokens.measure["line-height"]["lineheight-4"]};
`;

export const Percentage = styled(Heading)`
  color: ${tokens.color.component.txt["segmented-control"].item.default};
  font-family: ${tokens.fonts["font-family"].Barlow}!important;
  line-height: ${tokens.measure["line-height"]["lineheight-5"]};
  white-space: nowrap;
`;

export const ProgressBarContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: ${tokens.measure.space["spacing-2"]};
  align-items: center;
`;

export const Skeleton = styled.div<{ "align-center"?: boolean }>`
  display: flex;
  justify-content: ${(p) => (p["align-center"] ? "center" : "start")};
`;
