import { useMemo } from "react";

import { Checkpoints, TimerValue } from "@/@types/TimeValue";
import { createTimeModel } from "@/hooks/useTimeModel/instances/timeModel";
import { useTimeModel } from "@/hooks/useTimeModel/useTimeModel";
import { syncDateWithLocalTime } from "@/utils/syncDateWithLocalTime/syncDateWithLocalTime";

interface useCallTimeSyncProps {
  value: TimerValue;
  start: () => void;
  pause: () => void;
  resume: () => void;
  stop: () => void;
  reset: () => void;
  changeTime: (time: number) => void;
  changeLastUnit: (unit: string) => void;
  changeTimeToUpdate: (time: number) => void;
  changeDirection: (direction: string) => void;
  changeCheckpoints: (checkpoints: Checkpoints) => void;
}

export function useCallTimeSync(startCallDate?: string): useCallTimeSyncProps {
  const initialTime = useMemo(
    () =>
      startCallDate ? syncDateWithLocalTime(startCallDate).asMilliseconds() : 0,
    [startCallDate],
  );

  const timerOptions = useMemo(
    () =>
      createTimeModel({
        initialTime,
        startImmediately: !!startCallDate,
        timeToUpdate: 1000,
      }),
    [initialTime, startCallDate],
  );

  const resultTimeModel = useTimeModel(timerOptions);

  return resultTimeModel;
}
