import { constructEndpoint } from "@bees-grow-shared/services";

import { BasePathParamsRequest } from "@/@types/BasePathParamsRequest";
import { MICROSERVICES } from "@/services/MicroServices";

enum ENDPOINTS {
  GAP = "/accounts/{accountId}/gap",
}

export const GROW_GAP_SERVICE_ENDPOINTS = {
  GET_GAP: ({ path }: BasePathParamsRequest) =>
    constructEndpoint({
      service: MICROSERVICES.BEES_GROW_BFF,
      endpoint: ENDPOINTS.GAP,
      pathParams: { ...path },
    }),
};
