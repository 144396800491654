import { useMemo } from "react";
import { useIntl } from "react-intl";

import { SkeletonLoader } from "@hexa-ui/components";
import { Phone } from "@hexa-ui/icons";

import { formatTimePart } from "@/utils/formatTimePart/formatTimePart";

import { useCallTimerInfo } from "../../hooks/useCallTimerInfo/useCallTimerInfo";
import * as Styles from "./CallTimerInfo.styles";

interface CallTimerInfoProps {
  onFinishCall: () => void;
}

export function CallTimerInfo({ onFinishCall }: CallTimerInfoProps) {
  const icon = useMemo(() => <Phone size="medium" />, []);
  const { formatMessage } = useIntl();
  const {
    isLoading,
    timer,
    isCTIConnected,
    ctiEnabled,
    isOnCall,
    attempts,
    toggleCallState,
  } = useCallTimerInfo(onFinishCall);

  if (isLoading) {
    return <Loading />;
  }

  const labelText = isOnCall
    ? `${formatTimePart(timer.h)}:${formatTimePart(
        timer.m,
      )}:${formatTimePart(timer.s)}`
    : formatMessage({ id: "CallTimerInfo.START_CALL" });

  const ctiText = `CTI ${isCTIConnected ? formatMessage({ id: "CallTimerInfo.ONLINE" }) : formatMessage({ id: "CallTimerInfo.OFFLINE" })}`;

  return (
    <Styles.Container
      border="medium"
      elevated="minimal"
      data-testid={`${CallTimerInfo.name}`}
    >
      <div className="column">
        <div className="row">
          <Styles.Label data-testid={`${CallTimerInfo.name}-label`}>
            {labelText}
          </Styles.Label>
          <Styles.IconButton
            phone={isOnCall ? "on" : "off"}
            icon={() => icon}
            onClick={toggleCallState}
            data-testid={`${CallTimerInfo.name}-button`}
          ></Styles.IconButton>
        </div>
        <div className="row info">
          {ctiEnabled && (
            <Styles.Text
              bold="true"
              color={isCTIConnected ? "green" : "red"}
              data-testid={`${CallTimerInfo.name}-cti`}
            >
              {ctiText}
            </Styles.Text>
          )}
          <Styles.AttemptsContainer>
            <Styles.Text
              color="gray"
              data-testid={`${CallTimerInfo.name}-attempts-label`}
            >
              {`${formatMessage({ id: "CallTimerInfo.ATTEMPTS" })}: `}
            </Styles.Text>
            <Styles.Text
              bold="true"
              data-testid={`${CallTimerInfo.name}-attempts-value`}
            >
              {attempts}
            </Styles.Text>
          </Styles.AttemptsContainer>
        </div>
      </div>
    </Styles.Container>
  );
}

function Loading() {
  return (
    <Styles.Container
      border="medium"
      elevated="minimal"
      data-testid={`${CallTimerInfo.name}-${Loading.name}`}
      gap="true"
    >
      <div className="column">
        <div className="row">
          <SkeletonLoader height="40px"></SkeletonLoader>
        </div>
        <div className="row">
          <Styles.Skeleton>
            <SkeletonLoader height="14px" width="48px"></SkeletonLoader>
            <SkeletonLoader height="14px" width="27px"></SkeletonLoader>
          </Styles.Skeleton>
          <Styles.Skeleton>
            <SkeletonLoader height="14px" width="61px"></SkeletonLoader>
            <SkeletonLoader height="14px" width="17px"></SkeletonLoader>
          </Styles.Skeleton>
        </div>
      </div>

      <SkeletonLoader width="40px" height="40px"></SkeletonLoader>
    </Styles.Container>
  );
}
