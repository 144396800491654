import MessageMap from "../i18n.d";

const es419: MessageMap = {
  SummaryError: {
    ALL: "No se puede cargar algunas informaciones en esta página.",
    GAP: "No se puede cargar la información de la topline gap.",
    REWARDS: "No se pueden cargar las información del Club B.",
    CREDIT: "No se pueden cargar las información de crédito.",
    RETRY: "Intentar de nuevo",
  },
  CopyButton: {
    COPY: "Copiar",
    COPIED: "Copiado",
  },
  TopLineGap: {
    TITLE: "Oportunidad de gap",
    GOAL_TEXT: "Meta",
    GAP_TEXT: "Gap",
    ERROR_MESSAGE: "No se puede cargar la información de topline gap.",
  },
  PocInformation: {
    OWNER_NAME: "Propietario",
    SHOW_LESS: "Menos detalles",
    SHOW_MORE: "Mostrar más",
    POC_INFORMATION_TITLE: "Información del cliente",
    SALES_STRUCTURE_TITLE: "Estructura de ventas",
    POC_ID: "ID del cliente",
    ERP_ID: "ERP ID",
    TAX_ID: "Tax ID",
    LIQUOR_LICENSE_NUMBER: "Licencia",
    ACCOUNT_ID: "BEES account ID",
    EMAIL: "Email",
    FORMATTED_ADDRESS: "Dirección",
    SEGMENT: "Segmento",
    SUBSEGMENT: "Subsegmento",
    POTENTIAL: "Potencial",
    DDC: "DDC",
    SALES_REPRESENTATIVE: "Representante de ventas",
    SALES_ORGANIZATION: "Organización de ventas",
    SALES_DIRECTION: "Dirección de ventas",
    SALES_MANAGEMENT: "Gerencia de ventas",
    SALES_SUPERVISION: "Supervisión de ventas",
    SALES_ZONE: "Zona de ventas",
    CHANNEL: "Canal",
  },
  CreditAndPoints: {
    Leverage: {
      ClubB: {
        POINTS: "puntos",
        NOTENROLED: "No inscrito",
        NOTAVAILABLE: "No disponible",
        Error: {
          DESCRIPTION: "No se puede cargar la información del Club B.",
          RETRY_TITLE: "Tentar novamente",
        },
      },
    },
    Credit: {
      TITLE: "Línea de compra disponible",
      TOTAL_LIMIT: "Límite total",
      CREDIT_USE: "Valor en uso",
      CUMULATIVE_DISCOUNT: "Descuento acumulativo",
      EXPIRATION_DATE: "Fecha de vencimiento",
      ERROR_MESSAGE: "No se pueden cargar las información de crédito.",
      OVERDUE: "Vencido",
      PAYMENT: {
        INFORMATION: "Información de pago",
        METHOD: "Método de pago",
        PERIOD: "Período de pago",
      },
      REFERENCE_ID: "ID de referencia",
    },
  },
  CallTimerInfo: {
    START_CALL: "Iniciar llamada",
    ATTEMPTS: "Intentos",
    ONLINE: "Online",
    OFFLINE: "Offline",
  },
};

export default es419;
