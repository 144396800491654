import { useEffect, useState } from "react";

interface UseCallTimerInfoProps {
  timer: number;
  isLoading: boolean;
  isCTIEnable: boolean;
  isOnCall: boolean;
  attempts: number;
  formatSeconds: (seconds: number) => string;
  onClickButton: () => void;
}

export function useCallTimerInfo(): UseCallTimerInfoProps {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [timer, setTimer] = useState<number>(0);
  const [timerId, setTimerId] = useState<NodeJS.Timeout>();
  const [isCTIEnable, setIsCTIEnable] = useState<boolean>(undefined);
  const [isOnCall, setIsOnCall] = useState<boolean>(false);
  const [attempts, setAttempts] = useState<number>(0);
  const unitSecond = 1000;

  const startTimer = () => {
    setAttempts((prev) => prev + 1);
    setTimerId(
      setInterval(() => {
        setTimer((prevSeconds) => prevSeconds + 1);
      }, unitSecond),
    );
  };

  const stopTimer = () => {
    clearInterval(timerId);
    setTimer(0);
  };

  //Mock for work until done retrieve the data
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);
  //End of Mock

  const formatSeconds = (seconds: number): string => {
    const hours = String(Math.floor(seconds / 3600)).padStart(2, "0");
    const minutes = String(Math.floor((seconds % 3600) / 60)).padStart(2, "0");
    const remainingSeconds = String(seconds % 60).padStart(2, "0");
    return `${hours}:${minutes}:${remainingSeconds}`;
  };

  const changeState = (state?: boolean): boolean =>
    state === undefined ? true : !state;

  const onClickButton = () => {
    if (isOnCall) {
      stopTimer();
    } else {
      startTimer();
    }
    setIsCTIEnable((prev) => changeState(prev));
    setIsOnCall((prev) => !prev);
  };

  return {
    isLoading,
    timer,
    isCTIEnable,
    isOnCall,
    attempts,
    formatSeconds,
    onClickButton,
  };
}
