/* eslint-disable prettier/prettier */
import { tokens } from "@bees-grow-shared/theme";
import {
  Card,
  Paragraph,
  SkeletonLoader as SkeletonLoaderFromHexa,
} from "@hexa-ui/components";
import styled from "styled-components";

export const Container = styled(Card)<{ hasError: boolean }>`
  display: flex;
  height: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-top: ${tokens.measure.space["spacing-2"]};
  padding-bottom: ${tokens.measure.space["spacing-2"]};
  padding-left: ${tokens.measure.space["spacing-4"]};
  padding-right: ${tokens.measure.space["spacing-4"]};
  gap: ${tokens.measure.space["spacing-1"]};
  background-color: ${({ hasError }) =>
    hasError
      ? tokens.color.primitive.transparent.black["black-4"]
      : tokens.color.foundation.bg["primary-clear"]};

  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: flex-start;
    max-width: 189px;
    justify-content: center;
  }
`;

export const ClubName = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${tokens.measure.space["spacing-1"]};
  min-width: max-content;
`;

export const Title = styled(Paragraph)<{ hasError: boolean }>`
  color: ${({ hasError }) =>
    hasError
      ? tokens.color.foundation.txt.disabled
      : tokens.color.foundation.txt.default};
  line-height: 1.25rem;
  text-transform: capitalize;
`;

export const Value = styled(Paragraph)<{ hasError: boolean }>`
  line-height: 1.25rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: ${tokens.color.foundation.txt.default};
  display: ${({ hasError }) => (hasError ? "none" : "block")};
  max-width: 100%;
`;

export const SkeletonTitle = styled.div`
  width: 6.375rem;
  padding: ${tokens.measure.space["spacing-05"]} 0;
`;

export const SkeletonValue = styled.div`
  width: 4.5rem;
  padding: ${tokens.measure.space["spacing-05"]} 0;
`;

export const SkeletonLoader = styled(SkeletonLoaderFromHexa)`
  height: 1.25rem;
`;
