import { constructEndpoint } from "@bees-grow-shared/services";

import { BasePathParamsRequest } from "@/@types/BasePathParamsRequest";
import { MICROSERVICES } from "@/services/MicroServices";

const Projection = "REWARDS";

enum ENDPOINTS {
  REWARDS = "/accounts/{accountId}/rewards",
}

export const GROW_REWARDS_ENDPOINTS = {
  GET_REWARDS: ({ path }: BasePathParamsRequest): string =>
    constructEndpoint({
      service: MICROSERVICES.BEES_GROW_BFF,
      endpoint: ENDPOINTS.REWARDS,
      pathParams: { ...path },
      queryParams: { projection: Projection },
    }),
};
