import { OpenCallUseCase } from "@/services/openCall/upsertOpenCall/model/UpsertOpenCallRequest";
import { upsertOpenCall } from "@/services/openCall/upsertOpenCall/UpsertOpenCallService";

interface UpsertOpenCallUseCaseProps {
  callId: string;
  vendorId: string;
  startCall: Date;
}

export const upsertOpenCallUseCase = async ({
  callId,
  vendorId,
  startCall,
}: UpsertOpenCallUseCaseProps): Promise<void> => {
  await upsertOpenCall(
    {
      path: { callId },
      query: { useCase: OpenCallUseCase.START_CALL },
      headers: { vendorId },
    },
    {
      startCall,
    },
  );
};
