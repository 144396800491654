import { constructEndpoint } from "@bees-grow-shared/services";

import { MICROSERVICES } from "@/services/MicroServices";

import { GetCallCounterParams } from "./getCallCounter/model/GetCallCounterRequest";
import { UpsertOpenCallParams } from "./upsertOpenCall/model/UpsertOpenCallRequest";

enum ENDPOINTS {
  OPEN_CALL = "/agents/open-call/{callId}",
  CALL_COUNTER = "/accounts/{accountId}/call-attempts",
}

export const GROW_OPEN_CALL_SERVICE_ENDPOINTS = {
  OPEN_CALL: ({ path, query }: UpsertOpenCallParams) =>
    constructEndpoint({
      service: MICROSERVICES.BEES_GROW_BFF,
      endpoint: ENDPOINTS.OPEN_CALL,
      pathParams: { ...path },
      queryParams: { ...query },
    }),
  CALL_COUNTER: ({ path }: GetCallCounterParams) =>
    constructEndpoint({
      service: MICROSERVICES.BEES_GROW_BFF,
      endpoint: ENDPOINTS.CALL_COUNTER,
      pathParams: { ...path },
    }),
};
