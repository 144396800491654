import { createEffect } from "effector";

import { RewardsDomain } from "@/domains/rewardsDomain";
import { getRewardsUseCase } from "@/useCases/rewards/getRewards/getRewardsUseCase";

interface GetRewardsProps {
  accountId: string;
  vendorId: string;
}

export const getRewards = createEffect({
  async handler(props: GetRewardsProps): Promise<RewardsDomain> {
    const rewards = await getRewardsUseCase(props);

    return rewards;
  },
});
