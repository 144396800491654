import Moment, { Duration } from "moment";

export const RESPONSE_DATE_IN_MILLIS = "responseDateInMillis";
export const CURRENT_DATE_IN_MILLIS = "currentDateInMillis";
const DATE_FORMAT = "x";

export const syncDateWithLocalTime = (date: string): Duration => {
  const currentDateInMillis = localStorage.getItem(CURRENT_DATE_IN_MILLIS);
  const responseDateInMillis = localStorage.getItem(RESPONSE_DATE_IN_MILLIS);

  if (
    !currentDateInMillis ||
    !responseDateInMillis ||
    isNaN(Number(currentDateInMillis)) ||
    isNaN(Number(responseDateInMillis))
  ) {
    const time = Moment.utc();
    return Moment.duration(Math.abs(time.diff(Moment.utc(date))));
  }

  const currentMillis = Number(Moment.utc().format(DATE_FORMAT));
  const millis = Number(currentDateInMillis);
  const interval = currentMillis - millis;
  const responseDate = Moment.utc(responseDateInMillis, DATE_FORMAT);

  const time = responseDate.add(interval, "milliseconds");

  return Moment.duration(Math.abs(time.diff(Moment.utc(date))));
};
