import { useIntl } from "react-intl";

import { Paragraph } from "@hexa-ui/components";
import { Clock } from "@hexa-ui/icons";

import { formatTimePart } from "@/utils/formatTimePart/formatTimePart";

import { useCallSummaryModal } from "../../hooks/useCallSummaryModal";
import * as Styles from "./CallSummaryInfo.style";

export function CallSummaryInfo() {
  const {
    ctiEnabled,
    callTimerInfo: { isCTIConnected, attempts, timer },
  } = useCallSummaryModal();

  const { formatMessage } = useIntl();

  const labelText = `${formatTimePart(timer.h)}:${formatTimePart(
    timer.m,
  )}:${formatTimePart(timer.s)}`;

  return (
    <Styles.Content data-testid="CallSummaryInfo">
      <Paragraph size="small">
        <Clock size="large" />
        {formatMessage({ id: "CallSummaryModal.CALL_DURATION" })}
        <Styles.Timer>{labelText}</Styles.Timer>
      </Paragraph>
      {ctiEnabled && (
        <Paragraph size="small" data-testid="CallSummaryInfo-status">
          Status:
          <Styles.Status
            isCTIConnected={isCTIConnected}
            data-testid="CallSummaryInfo-status-text"
          >
            {isCTIConnected
              ? formatMessage({ id: "CallSummaryModal.CONNECTED" })
              : formatMessage({ id: "CallSummaryModal.DISCONNECTED" })}
          </Styles.Status>
        </Paragraph>
      )}
      <Paragraph size="small">
        {formatMessage({ id: "CallSummaryModal.CALL_ATTEMPTS" })}
        <Styles.Attempts>{attempts}</Styles.Attempts>
      </Paragraph>
    </Styles.Content>
  );
}
