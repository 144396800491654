import { useMemo } from "react";
import { useIntl } from "react-intl";

import { SkeletonLoader } from "@hexa-ui/components";
import { Phone } from "@hexa-ui/icons";

import * as Styles from "./CallTimerInfo.styles";
import { useCallTimerInfo } from "./hooks/useCallTimerInfo";

export const CallTimerInfo = (): JSX.Element => {
  const icon = useMemo(() => <Phone size="medium" />, []);
  const { formatMessage } = useIntl();
  const {
    isLoading,
    timer,
    isCTIEnable,
    isOnCall,
    attempts,
    formatSeconds,
    onClickButton,
  } = useCallTimerInfo();

  if (isLoading) {
    return <Loading />;
  }

  const labelText = isOnCall
    ? formatSeconds(timer)
    : formatMessage({ id: "CallTimerInfo.START_CALL" });
  const ctiText = `CTI ${isCTIEnable ? formatMessage({ id: "CallTimerInfo.ONLINE" }) : formatMessage({ id: "CallTimerInfo.OFFLINE" })}`;

  return (
    <Styles.Container
      border="medium"
      elevated="minimal"
      data-testid={`${CallTimerInfo.name}`}
    >
      <div className="column">
        <div className="row">
          <Styles.Label data-testid={`${CallTimerInfo.name}-label`}>
            {labelText}
          </Styles.Label>
        </div>
        <div className="row">
          {isCTIEnable === undefined ? (
            <></>
          ) : (
            <Styles.Text
              bold="true"
              color={isCTIEnable ? "green" : "red"}
              data-testid={`${CallTimerInfo.name}-cti`}
            >
              {ctiText}
            </Styles.Text>
          )}
          <Styles.AttemptsContainer>
            <Styles.Text
              color="gray"
              data-testid={`${CallTimerInfo.name}-attempts-label`}
            >
              {`${formatMessage({ id: "CallTimerInfo.ATTEMPTS" })}: `}
            </Styles.Text>
            <Styles.Text
              bold="true"
              data-testid={`${CallTimerInfo.name}-attempts-value`}
            >
              {attempts}
            </Styles.Text>
          </Styles.AttemptsContainer>
        </div>
      </div>
      <Styles.IconButton
        phone={isOnCall ? "on" : "off"}
        icon={() => icon}
        onClick={() => onClickButton()}
        data-testid={`${CallTimerInfo.name}-button`}
      ></Styles.IconButton>
    </Styles.Container>
  );
};

function Loading() {
  return (
    <Styles.Container
      border="medium"
      elevated="minimal"
      data-testid={`${CallTimerInfo.name}-${Loading.name}`}
      gap="true"
    >
      <div className="column">
        <div className="row">
          <SkeletonLoader height="40px"></SkeletonLoader>
        </div>
        <div className="row">
          <Styles.Skeleton>
            <SkeletonLoader height="14px" width="48px"></SkeletonLoader>
            <SkeletonLoader height="14px" width="27px"></SkeletonLoader>
          </Styles.Skeleton>
          <Styles.Skeleton>
            <SkeletonLoader height="14px" width="61px"></SkeletonLoader>
            <SkeletonLoader height="14px" width="17px"></SkeletonLoader>
          </Styles.Skeleton>
        </div>
      </div>

      <SkeletonLoader width="40px" height="40px"></SkeletonLoader>
    </Styles.Container>
  );
}
