import { useIntl } from "react-intl";

import { SkeletonLoader } from "@hexa-ui/components";

import { useTopLineGap } from "./hooks/useTopLineGap";
import * as Styles from "./TopLineGap.styles";

export const TopLineGap = (): JSX.Element => {
  const { formatMessage } = useIntl();
  const maxValuePercentage = 100;

  const { gapFormatted, forecastFormatted, percentage, isLoading, error } =
    useTopLineGap();

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Styles.Container
      disabled={error}
      border="medium"
      elevated="minimal"
      data-testid={`${TopLineGap.name}`}
    >
      <Styles.Title data-testid={`${TopLineGap.name}-title`}>
        {`${formatMessage({ id: "TopLineGap.TITLE" })}`}
      </Styles.Title>
      <div data-testid={`${TopLineGap.name}-body`}>
        <Styles.Text data-testid={`${TopLineGap.name}-goal`}>
          {`${formatMessage({ id: "TopLineGap.GOAL_TEXT" })}: `}
          <Styles.CurrencyValue>
            {error ? "-" : forecastFormatted}
          </Styles.CurrencyValue>
        </Styles.Text>
        <Styles.ProgressBarContainer
          data-testid={`${TopLineGap.name}-progress`}
        >
          <Styles.ProgressBar
            maxValue={maxValuePercentage}
            value={percentage}
          />
          <Styles.Percentage
            size="H5"
            data-testid={`${TopLineGap.name}-percentage`}
          >{`${error ? "-" : percentage}%`}</Styles.Percentage>
        </Styles.ProgressBarContainer>
      </div>
      <Styles.Text
        bold="true"
        align-center="true"
        data-testid={`${TopLineGap.name}-gap`}
      >
        {`${formatMessage({ id: "TopLineGap.GAP_TEXT" })} `}
        <Styles.CurrencyValue>
          {error ? "-" : gapFormatted}
        </Styles.CurrencyValue>
      </Styles.Text>
    </Styles.Container>
  );
};

function Loading() {
  return (
    <Styles.Container
      border="medium"
      elevated="minimal"
      data-testid={`${TopLineGap.name}-${Loading.name}`}
      gap="true"
    >
      <Styles.Skeleton align-center>
        <SkeletonLoader height="16px" width="86px"></SkeletonLoader>
      </Styles.Skeleton>
      <Styles.Skeleton>
        <SkeletonLoader width="139px" height="12px"></SkeletonLoader>
      </Styles.Skeleton>
      <Styles.Skeleton>
        <SkeletonLoader width="100%" height="8px"></SkeletonLoader>
      </Styles.Skeleton>
      <Styles.Skeleton align-center>
        <SkeletonLoader width="120px" height="12px"></SkeletonLoader>
      </Styles.Skeleton>
    </Styles.Container>
  );
}
