import MessageMap from "../i18n.d";

const enUS: MessageMap = {
  SummaryError: {
    ALL: "Unable to load some information in this page.",
    GAP: "Unable to load the topline gap information.",
    REWARDS: "Unable to load Club B information. ",
    CREDIT: "Unable to load the credit information",
    RETRY: "Try again",
  },
  CopyButton: {
    COPY: "Copy",
    COPIED: "Copied",
  },
  TopLineGap: {
    TITLE: "Topline opportunity",
    GOAL_TEXT: "Goal",
    GAP_TEXT: "Gap",
    ERROR_MESSAGE: "Unable to load the topline gap information.",
  },
  PocInformation: {
    OWNER_NAME: "Owner",
    SHOW_LESS: "Show less",
    SHOW_MORE: "Show more",
    POC_INFORMATION_TITLE: "POC information",
    SALES_STRUCTURE_TITLE: "Sales structure",
    POC_ID: "POC ID",
    ERP_ID: "ERP ID",
    TAX_ID: "Tax ID",
    LIQUOR_LICENSE_NUMBER: "Liquor license",
    ACCOUNT_ID: "BEES account ID",
    EMAIL: "Email",
    FORMATTED_ADDRESS: "Address",
    SEGMENT: "Segment",
    SUB_SEGMENT: "Sub-segment",
    POTENTIAL: "Potential",
    DDC: "DDC",
    SALES_REPRESENTATIVE: "Sales representative",
    SALES_ORGANIZATION: "Sales organization",
    SALES_DIRECTION: "Sales direction",
    SALES_MANAGEMENT: "Sales management",
    SALES_SUPERVISION: "Sales supervision",
    SALES_ZONE: "Sales zone",
    CHANNEL: "Channel",
  },
  CreditAndPoints: {
    Leverage: {
      ClubB: {
        POINTS: "points",
        NOTENROLED: "Not enrolled",
        NOTAVAILABLE: "Not available",
        Error: {
          DESCRIPTION: "Unable to load the Club B information.",
          RETRY_TITLE: "try again",
        },
      },
    },
    Credit: {
      TITLE: "Available credit",
      TOTAL_LIMIT: "Total limit",
      CREDIT_USE: "Credit used",
      CUMULATIVE_DISCOUNT: "Cumulative discount",
      EXPIRATION_DATE: "Expiration date",
      ERROR_MESSAGE: "Unable to load the credit information.",
      OVERDUE: "Overdue",
      Payment: {
        INFORMATION: "Payment information",
        METHOD: "Payment method",
        PERIOD: "Payment period",
      },
      REFERENCE_ID: "Reference ID",
    },
  },
  CallTimerInfo: {
    START_CALL: "Start call",
    ATTEMPTS: "Attempts",
    ONLINE: "Online",
    OFFLINE: "Offline",
  },
};

export default enUS;
