import { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { useToast } from "@bees-grow-shared/business-components";
import {
  ctiSharedService,
  PhoneResponseType,
  PocCallOrigin,
  RT4PublishCommandEnum,
  useSharedUnit,
} from "@bees-grow-shared/services";

interface UseCTIConnectionProps {
  ctiEnabled: boolean;
  phones: Array<PhoneResponseType>;
  pocCallOrigin: PocCallOrigin;
  phoneRankDone: boolean;
  isModalOpen: boolean;
}

export function useCTIConnection({
  ctiEnabled,
  phones,
  pocCallOrigin,
  phoneRankDone,
  isModalOpen,
}: UseCTIConnectionProps) {
  const ctiService = ctiSharedService();
  const toast = useToast();
  const { formatMessage } = useIntl();
  const { isConnected: isCTIConnected } = useSharedUnit(ctiService);
  const [callBestPhone, setCallBestPhone] = useState<boolean>(false);
  const [firstAttemptConnectionCTI, setFirstAttempConnectionCTI] =
    useState<boolean>(false);

  useEffect(() => {
    if (ctiEnabled && !isCTIConnected) {
      ctiService.open();
    }
  }, [ctiEnabled, ctiService, isCTIConnected, toast]);

  const handleStartCTI = useCallback(() => {
    const bestPhoneToCall = phones[0];

    if (pocCallOrigin !== PocCallOrigin.AUTO_DIALER && !callBestPhone) {
      ctiService.send({
        command: RT4PublishCommandEnum.MAKE_CALL,
        payload: {
          phoneNumber: bestPhoneToCall.phoneNumber,
        },
      });

      setCallBestPhone(true);
    }
  }, [callBestPhone, ctiService, phones, pocCallOrigin]);

  useEffect(() => {
    if (
      (isCTIConnected === undefined || !isCTIConnected) &&
      ctiEnabled &&
      !firstAttemptConnectionCTI &&
      phoneRankDone &&
      !isModalOpen
    ) {
      toast({
        showCloseButton: true,
        type: "error",
        message: formatMessage({
          id: "CallTimerInfo.ToastMessage.CTI_CONNECTION_FAILED",
        }),
      });

      setFirstAttempConnectionCTI(true);
    }
  }, [
    ctiEnabled,
    firstAttemptConnectionCTI,
    formatMessage,
    isCTIConnected,
    isModalOpen,
    phoneRankDone,
    toast,
  ]);

  return {
    isCTIConnected,
    handleStartCTI,
  };
}
