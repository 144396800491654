import { useEffect } from "react";

import {
  accountSharedService,
  useCurrencyFormatter,
  useSharedUnit,
} from "@bees-grow-shared/services";
import { useUnit } from "effector-react";

import { getGap } from "@/stores/gap/GapEvents";
import GapStore from "@/stores/gap/GapStore";

export function useTopLineGap() {
  const { gap, forecast, percentage, isLoading, error } = useUnit(GapStore);
  const accountService = accountSharedService();
  const { accountId, vendorId } = useSharedUnit(accountService);

  const minimumFractionDigits = 2;

  useEffect(() => {
    getGap({ accountId, vendorId });
  }, [accountId, vendorId]);

  const gapFormatted = useCurrencyFormatter(gap, {
    minimumFractionDigits,
  });

  const forecastFormatted = useCurrencyFormatter(forecast, {
    minimumFractionDigits,
  });

  return {
    forecastFormatted,
    gapFormatted,
    percentage,
    isLoading,
    error,
  };
}
