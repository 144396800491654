import { createStore } from "effector";

import {
  getCallCounter,
  setIsModalOpen,
  setIsStartedCall,
  upsertOpenCall,
} from "./OpenCallEvent";
import { OpenCallState, openCallStoreInitialState } from "./OpenCallState";

const OpenCallStore = createStore(openCallStoreInitialState)
  .on(setIsModalOpen, (state, payload) => ({
    ...state,
    ...payload,
  }))
  .on(setIsStartedCall, (state, payload) => ({
    ...state,
    ...payload,
  }))
  .on(upsertOpenCall, (state: OpenCallState): OpenCallState => {
    return {
      ...state,
      isLoading: true,
      done: false,
      error: false,
    };
  })
  .on(
    upsertOpenCall.fail,
    (state: OpenCallState): OpenCallState => ({
      ...state,
      isLoading: false,
      done: true,
      error: true,
    }),
  )
  .on(upsertOpenCall.done, (state: OpenCallState): OpenCallState => {
    return {
      ...state,
      isLoading: false,
      done: true,
      error: false,
    };
  })
  .on(getCallCounter, (state: OpenCallState): OpenCallState => {
    return {
      ...state,
      isLoading: true,
      done: false,
      error: false,
    };
  })
  .on(getCallCounter.fail, (state: OpenCallState): OpenCallState => {
    return {
      ...state,
      isLoading: false,
      done: true,
      error: true,
    };
  })
  .on(
    getCallCounter.done,
    (state: OpenCallState, { result }): OpenCallState => {
      return {
        ...state,
        isLoading: false,
        done: true,
        error: false,
        callAttempts: result.callAttempts,
      };
    },
  );

export default OpenCallStore;
