import { tokens } from "@bees-grow-shared/theme";
import styled from "styled-components";

export const SUMMARY_HEIGHT_HD = "10.5rem";
export const SUMMARY_HEIGHT_DEFAULT = "9.375rem";

export const MainContainer = styled.header`
  display: grid;
  gap: ${tokens.measure.space["spacing-4"]};
  max-height: ${SUMMARY_HEIGHT_DEFAULT};
  height: auto;
  grid-template-columns:
    minmax(284px, 0.298fr)
    minmax(154px, 0.22fr)
    minmax(189px, 0.26fr)
    minmax(206px, 0.221fr);

  @media (max-width: 1024px) {
    height: ${SUMMARY_HEIGHT_HD};
    max-height: ${SUMMARY_HEIGHT_HD};
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 1.5rem;

  li {
    background: white;
  }
  li:nth-child(4n-1),
  li:nth-child(4n-2) {
    background: ${tokens.color.primitive.solid.neutral["neutral-10"]};
  }
`;
