import { createStore } from "effector";

import { getRewards } from "./RewardsEvents";
import { rewardsInitialState, RewardsState } from "./RewardsState";

const RewardsStore = createStore(rewardsInitialState)
  .on(getRewards, (state: RewardsState): RewardsState => {
    return {
      ...state,
      isLoading: true,
      done: false,
      error: false,
    };
  })
  .on(
    getRewards.fail,
    (state: RewardsState): RewardsState => ({
      ...state,
      isLoading: false,
      done: true,
      error: true,
    }),
  )
  .on(getRewards.done, (state: RewardsState, { result }): RewardsState => {
    return {
      ...state,
      ...result,
      isLoading: false,
      done: true,
      error: false,
    };
  });

export default RewardsStore;
