import { RewardsDomain } from "@/domains/rewardsDomain";
import { RewardsResponse } from "@/services/rewards/getRewards/GetRewardsResponse";

export const getRewardsConverter = (
  response: RewardsResponse,
): RewardsDomain => {
  const { balance, dateRegistered, tier } = response.rewards;
  return {
    rewards: {
      tier: tier,
      balance: balance,
      dateRegistered: dateRegistered,
    },
  } as RewardsDomain;
};
