import { useIntl } from "react-intl";

import { ChevronDown, ChevronUp } from "@hexa-ui/icons";

import * as Styles from "./CreditHeader.styles";

interface CreditHeaderProps {
  isDropdownOpen: boolean;
  setIsDropdownOpen: (value: boolean | ((prev: boolean) => boolean)) => void;
  isLoading: boolean;
  hasError: boolean;
  available: string;
}

export default function CreditHeader({
  isDropdownOpen,
  setIsDropdownOpen,
  isLoading,
  hasError,
  available,
}: Readonly<CreditHeaderProps>) {
  const { formatMessage } = useIntl();

  function handleDropdown() {
    if (!hasError) {
      setIsDropdownOpen((prev) => !prev);
    }
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Styles.CardHeader data-testid={`${CreditHeader.name}`}>
      <Styles.HeaderContent>
        <div>
          <Styles.CreditTitle
            data-testid={`${CreditHeader.name}-title`}
            weight="semibold"
            size="small"
          >
            {formatMessage({ id: "CreditAndPoints.Credit.TITLE" })}
          </Styles.CreditTitle>
          <Styles.CreditValue
            data-testid={`${CreditHeader.name}-value`}
            weight="normal"
            size="small"
          >
            {hasError ? "-" : available}
          </Styles.CreditValue>
        </div>
        <Styles.CollapsibleButton
          hasUnderline={false}
          onClick={handleDropdown}
          data-testid={`${CreditHeader.name}-collapsible-button`}
        >
          {isDropdownOpen ? (
            <ChevronUp size="medium" />
          ) : (
            <ChevronDown size="medium" />
          )}
        </Styles.CollapsibleButton>
      </Styles.HeaderContent>
    </Styles.CardHeader>
  );
}

function Loading() {
  return (
    <Styles.CardHeader data-testid={`${CreditHeader.name}-${Loading.name}`}>
      <Styles.SkeletonContent>
        <Styles.SkeletonLoaderTitle />
        <Styles.SkeletonLoaderValue />
      </Styles.SkeletonContent>
    </Styles.CardHeader>
  );
}
