import { useEffect, useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";

import {
  accountSharedService,
  useNumberFormatter,
  useSharedUnit,
} from "@bees-grow-shared/services";
import { useUnit } from "effector-react";

import { getRewards } from "@/stores/rewards/RewardsEvents";
import RewardsStore from "@/stores/rewards/RewardsStore";

interface UseLeverageProps {
  rewardsData: {
    title: string;
    points: string;
    dateRegistered: string;
  };
  isLeverageLoading: boolean;
  hasLeverageError: boolean;
  isTruncated: boolean;
  textRef: React.RefObject<HTMLDivElement>;
}

export function isTextOverflowing(textElement: HTMLElement | null) {
  if (!textElement) return false;
  const isOverflowing = textElement.scrollWidth > textElement.offsetWidth;
  return isOverflowing;
}

export const useLeverage = (): UseLeverageProps => {
  const {
    isLoading: isLeverageLoading,
    error: hasLeverageError,
    rewards: { tier, balance, dateRegistered },
  } = useUnit(RewardsStore);
  const [isTruncated, setIsTruncated] = useState(false);
  const textRef = useRef<HTMLDivElement | null>(null);
  const { formatMessage } = useIntl();
  const accountService = accountSharedService();
  const { accountId, vendorId } = useSharedUnit(accountService);

  const formatPointsText = `${useNumberFormatter(balance)} ${formatMessage({
    id: "CreditAndPoints.Leverage.ClubB.POINTS",
  })}`;

  const removeSpecialCharacters = useMemo(
    () => () => tier.replace(/[^\w\s]|_/gi, " ").toLowerCase(),
    [tier],
  );

  useEffect(() => {
    if (textRef.current) {
      setIsTruncated(isTextOverflowing(textRef.current));
    }
  }, [formatPointsText]);

  useEffect(() => {
    getRewards({ accountId, vendorId });
  }, [accountId, vendorId]);

  const rewardsData = {
    title: removeSpecialCharacters(),
    points: formatPointsText,
    dateRegistered,
  };

  return {
    isLeverageLoading,
    hasLeverageError,
    rewardsData,
    isTruncated,
    textRef,
  };
};
