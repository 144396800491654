/* eslint-disable no-console */
import { useCallback, useEffect } from "react";

import {
  accountSharedService,
  agentCallSharedService,
  autoDialerSharedService,
  cartSharedService,
  ClassificationEnum,
  growSettingsSharedService,
  phoneRankSharedService,
  PhoneRequestType,
  PhoneResponseType,
  PhoneTypeEnum,
  PocCallOrigin,
  ScoreEnum,
  userConfigSharedService,
  useSharedUnit,
} from "@bees-grow-shared/services";
import { useUnit } from "effector-react";
import { z } from "zod";

import { CallClassificationEnum } from "@/@types/CallClassification";
import { TimeParts, TimerValue } from "@/@types/TimeValue";
import { callEnded } from "@/config/typewriter";
import { callLaterOptions } from "@/constant/CallLaterOptions";
import { useCallTimerInfo } from "@/hooks/useCallTimerInfo/useCallTimerInfo";
import { setIsModalOpen } from "@/stores/openCall/OpenCallEvent";
import OpenCallStore from "@/stores/openCall/OpenCallStore";

const baseCallSummarySchema = z.object({
  callClassification: z.nativeEnum(CallClassificationEnum),
  callLater: z.string().optional(),
  comments: z.string(),
  submitter: z.string().optional(),
});
const callSummarySchema = baseCallSummarySchema.superRefine((data, ctx) => {
  if (
    (data.callClassification === CallClassificationEnum.CALL_LATER_TODAY ||
      data.callClassification === CallClassificationEnum.NO_ANSWER_TRY_AGAIN) &&
    !data.callLater
  ) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      params: { callClassification: data.callClassification },
      path: ["callLater"],
    });
  }
});

type CallSummarySchema = z.infer<typeof baseCallSummarySchema>;

interface UseCallSummaryModalProps {
  isModalOpen: boolean;
  pocCallOrigin: PocCallOrigin;
  ctiEnabled: boolean;
  cartDetails?: string;
  callTimerInfo: {
    attempts: number;
    isCTIConnected: boolean;
    timer: TimerValue;
  };
  callLaterOptions: {
    value: string;
    label: string;
  }[];
  callSummarySchema: z.ZodEffects<
    z.ZodObject<
      {
        callClassification: z.ZodNativeEnum<typeof CallClassificationEnum>;
        callLater: z.ZodOptional<z.ZodString>;
        comments: z.ZodString;
      },
      "strip",
      z.ZodTypeAny,
      {
        callClassification?: string;
        callLater?: string;
        comments?: string;
      }
    >
  >;
  actions: {
    handleSubmitCallSummary: (data: CallSummarySchema) => void;
    handleToggleModal: () => void;
  };
}

export function useCallSummaryModal(): UseCallSummaryModalProps {
  const { isModalOpen } = useUnit(OpenCallStore);
  const cartService = cartSharedService();
  const cartDetails = useSharedUnit(cartService).details;

  const agentCallService = agentCallSharedService();
  const { pocCallOrigin } = useSharedUnit(agentCallService);

  const autoDialerService = autoDialerSharedService();
  const { phoneAutoDialer } = useSharedUnit(autoDialerService);
  const isAutoDialer = pocCallOrigin === PocCallOrigin.AUTO_DIALER;
  const phoneRankService = phoneRankSharedService();

  const accountService = accountSharedService();
  const { vendorId, accountId } = useSharedUnit(accountService);

  const { phones } = useSharedUnit(phoneRankService);

  const growSettingsService = growSettingsSharedService();
  const { minCallDuration } = useSharedUnit(growSettingsService);

  const userConfigService = userConfigSharedService();
  const { orgId } = useSharedUnit(userConfigService);

  const handleToggleModal = useCallback(() => {
    setIsModalOpen({
      isModalOpen: !isModalOpen,
    });
  }, [isModalOpen]);

  const { attempts, isCTIConnected, timer, ctiEnabled } =
    useCallTimerInfo(handleToggleModal);

  useEffect(() => {
    growSettingsService.getGrowSettings({
      orgId,
      vendorId,
    });
  }, [growSettingsService, orgId, vendorId]);

  const convertTime = useCallback((time: TimeParts) => {
    let totalSeconds = 0;

    if (time.d > 0) {
      const dayToSecond = time.d * 86400;
      totalSeconds += dayToSecond;
    }

    if (time.h > 0) {
      const hourToSecond = time.h * 3600;
      totalSeconds += hourToSecond;
    }

    if (time.m > 0) {
      const minuteToSecond = time.m * 60;
      totalSeconds += minuteToSecond;
    }

    return totalSeconds + time.s + time.ms / 1000;
  }, []);

  const createNewContact = useCallback(
    (phones: PhoneResponseType[], phoneNumber: string) => {
      const baseName = "New Contact";
      const suffix = phones.filter(({ phoneContact }) =>
        phoneContact?.startsWith(baseName),
      ).length;

      const newName = `${baseName}${suffix > 0 ? ` ${suffix}` : ""}`;

      return [
        {
          phoneNumber,
          classification: ClassificationEnum.INPUTTED,
          contactName: newName,
          phoneType: PhoneTypeEnum.INPUTTED_PHONE,
        },
      ];
    },
    [],
  );

  const handleCallClassification = useCallback(async () => {
    const foundPhone = phones.find(
      (phone) => phone.phoneNumber === phoneAutoDialer,
    );

    if (!foundPhone) {
      const newContact = createNewContact(phones, phoneAutoDialer);

      await phoneRankService.postPhoneRank({
        params: {
          accountId,
          vendorId,
        },
        phones: newContact,
      });

      return;
    }

    const isInactive = foundPhone.score === ScoreEnum.INACTIVE;

    const updatedPhone: PhoneRequestType = {
      contactName: foundPhone.phoneContact,
      phoneNumber: phoneAutoDialer,
      classification: isInactive
        ? ClassificationEnum.ACTIVATE
        : ClassificationEnum.GOOD,
      phoneType: PhoneTypeEnum.INPUTTED_PHONE,
    };

    await phoneRankService.updatePhoneRank({
      params: {
        accountId,
        vendorId,
        phoneNumber: phoneAutoDialer,
      },
      phone: updatedPhone,
    });
  }, [
    accountId,
    vendorId,
    phoneAutoDialer,
    phoneRankService,
    phones,
    createNewContact,
  ]);

  const handleSubmitCallSummary = useCallback(
    (data: CallSummarySchema) => {
      /* eslint-disable camelcase */
      callEnded({
        call_origin: pocCallOrigin,
        contact_classification: data.callClassification,
        general_comments: data.comments,
        finisher_button: data.submitter,
      });

      const callIsValid = Math.floor(convertTime(timer)) >= minCallDuration;

      const isSuccessContact =
        data.callClassification.valueOf() ===
        CallClassificationEnum.SUCCESS_ON_CONTACT.valueOf();

      if (isAutoDialer && isSuccessContact && callIsValid) {
        handleCallClassification();
      }
    },
    [
      pocCallOrigin,
      minCallDuration,
      convertTime,
      timer,
      handleCallClassification,
      isAutoDialer,
    ],
  );

  return {
    isModalOpen,
    ctiEnabled,
    callTimerInfo: { attempts, isCTIConnected, timer },
    cartDetails,
    callLaterOptions,
    pocCallOrigin,
    callSummarySchema,
    actions: {
      handleToggleModal,
      handleSubmitCallSummary,
    },
  };
}
