import { tokens } from "@bees-grow-shared/theme";
import styled from "styled-components";

export const MainContainer = styled.header`
  display: grid;
  grid-template-columns:
    minmax(399px, 0.3345fr)
    repeat(2, minmax(262px, 0.2216fr))
    minmax(259px, 0.2201fr);
  gap: 1rem;
  max-height: 7rem;
  height: 7rem;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 1.5rem;
  li {
    background: white;
  }
  li:nth-child(4n-1),
  li:nth-child(4n-2) {
    background: ${tokens.color.primitive.solid.neutral["neutral-10"]};
  }
`;
