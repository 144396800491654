import { tokens } from "@bees-grow-shared/theme";
import { Paragraph, SkeletonLoader, TextLink } from "@hexa-ui/components";
import styled from "styled-components";

export const CardHeader = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HeaderContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  & > div {
    display: flex;
    flex-direction: column;
    max-width: calc(100% - 7.25rem);
  }
`;

export const CreditTitle = styled(Paragraph)`
  color: ${tokens.color.foundation.txt.default};
  margin-bottom: ${tokens.measure.space["spacing-1"]};
  line-height: 1.25rem;
  white-space: nowrap;
`;

export const CreditValue = styled(Paragraph)`
  color: ${tokens.color.foundation.txt.success};
  line-height: ${tokens.measure["line-height"]["lineheight-5"]};
`;

export const CollapsibleButton = styled(TextLink)`
  font-weight: ${tokens.fonts["font-weight"].medium};
  font-size: ${tokens.measure["font-size"]["fontsize-2"]};
  line-height: ${tokens.measure["line-height"]["lineheight-3"]};
  white-space: nowrap;
  display: flex;
  color: ${tokens.color.component.icon.dropdown.default};

  &:hover {
    text-decoration: none;
    color: ${tokens.color.component.icon.dropdown.hover};
  }
`;

export const SkeletonLoaderTitle = styled(SkeletonLoader)`
  width: 8.188rem;
  padding: 0.1875rem 0;
  height: 0.875rem;
  margin-bottom: ${tokens.measure.space["spacing-1"]};
`;
export const SkeletonLoaderValue = styled(SkeletonLoader)`
  width: 5.313rem;
  padding: 0.1875rem 0;
  height: 0.875rem;
`;

export const SkeletonContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.438rem;
`;
