import { useEffect, useState } from "react";
import { useIntl } from "react-intl";

import {
  accountSharedService,
  useSharedUnit,
} from "@bees-grow-shared/services";
import { useUnit } from "effector-react";

import { useCallSummaryModal } from "@/components/callSummaryModal/hooks/useCallSummaryModal";
import { getCredit } from "@/stores/credit/CreditEvents";
import CreditStore from "@/stores/credit/CreditStore";
import { getGap } from "@/stores/gap/GapEvents";
import GapStore from "@/stores/gap/GapStore";
import { getRewards } from "@/stores/rewards/RewardsEvents";
import RewardsStore from "@/stores/rewards/RewardsStore";

interface UseSummaryProps {
  errorMessage: string;
  handleRetry: () => void;
  handleToggleModal: () => void;
  isModalOpen: boolean;
}

export function useSummary(): UseSummaryProps {
  const { formatMessage } = useIntl();
  const { error: creditError } = useUnit(CreditStore);
  const { error: rewardsError } = useUnit(RewardsStore);
  const { error: gapError } = useUnit(GapStore);
  const [errorMessage, setErrorMessage] = useState("");
  const accountService = accountSharedService();
  const { accountId, vendorId } = useSharedUnit(accountService);
  const {
    isModalOpen,
    actions: { handleToggleModal },
  } = useCallSummaryModal();

  useEffect(() => {
    const errors = [
      creditError && formatMessage({ id: "SummaryError.CREDIT" }),
      gapError && formatMessage({ id: "SummaryError.GAP" }),
      rewardsError && formatMessage({ id: "SummaryError.REWARDS" }),
    ].filter(Boolean);

    if (errors.length > 1) {
      setErrorMessage(formatMessage({ id: "SummaryError.ALL" }));
    } else if (errors.length === 1) {
      setErrorMessage(errors[0]);
    } else {
      setErrorMessage("");
    }
  }, [creditError, rewardsError, gapError, formatMessage]);

  const handleRetry = () => {
    if (creditError) {
      getCredit({ accountId, vendorId });
    }
    if (gapError) {
      getGap({ accountId, vendorId });
    }
    if (rewardsError) {
      getRewards({ accountId, vendorId });
    }
  };

  return { errorMessage, handleRetry, handleToggleModal, isModalOpen };
}
