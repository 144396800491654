import { tokens } from "@bees-grow-shared/theme";
import { Card } from "@hexa-ui/components";
import styled from "styled-components";

const DROPDOWN_OPEN_HEIGHT = "25rem";
const DROPDOWN_CLOSED_HEIGHT = "3.75rem";

export const Wrapper = styled.div<{ disabled?: boolean }>`
  position: relative;
  width: 100%;
  height: 100%;

  &[disabled] > div {
    background-color: ${tokens.color.primitive.transparent.black["black-4"]};
    border-color: rgba(20, 20, 20, ${tokens.measure.opacity.light});
  }

  &[disabled] svg,
  &[disabled] h5,
  &[disabled] span,
  &[disabled] p {
    color: rgba(20, 20, 20, ${tokens.measure.opacity.medium});
  }
`;

export const Container = styled(Card)<{ isDropdownOpen: boolean }>`
  position: absolute;
  overflow: hidden;
  max-width: 41.1875rem;
  max-height: ${(props) =>
    props.isDropdownOpen ? DROPDOWN_OPEN_HEIGHT : DROPDOWN_CLOSED_HEIGHT};
  width: 100%;
  height: auto;
  z-index: 2;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${(props) =>
    props.isDropdownOpen
      ? tokens.measure.space["spacing-4"]
      : `${tokens.measure.space["spacing-2"]} ${tokens.measure.space["spacing-4"]}`};
  box-shadow: ${(props) =>
    props.isDropdownOpen
      ? tokens.shadow["elevation-4"]
      : tokens.shadow["elevation-025"]};
  & > div {
    width: 100%;
  }
  transition: all 500ms ease-in-out;
`;
