import { axiosInstance } from "@bees-grow-shared/services";

import { GROW_OPEN_CALL_SERVICE_ENDPOINTS } from "../endpoints";
import { GetCallCounterParams } from "./model/GetCallCounterRequest";

interface GetCallCounterResponse {
  attempts: number;
}

export async function getCallCounter(
  params: GetCallCounterParams,
): Promise<GetCallCounterResponse> {
  const { data } = await axiosInstance.get<GetCallCounterResponse>(
    GROW_OPEN_CALL_SERVICE_ENDPOINTS.CALL_COUNTER(params),
    { headers: { ...params.headers } },
  );

  return data;
}
